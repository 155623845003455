import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { getActiveGuestWithoutNew, getCurrentEvent } from 'selectors/event'
import { useRouter } from 'next/router'
import { useUser } from '../../utils/userFunctions'
import apiClient from '../../shared-components/utils/ApiClient'
import {
  addGuestToEvent,
  loadGuestLocal,
  setCurrentEvent,
  updateHypeScore,
} from '../../actions/event'
import dayjs from 'dayjs'
import {
  fetchFriends,
  fetchUserByCode,
  setFromUserCode,
} from '../../actions/user'
import { setSnackbar } from '../../shared-components/redux/notifications/actions'
import { getGuestFirstName } from '../../shared-components/utils/eventDisplay'
import mixpanel from '../../shared-components/utils/mixpanel'
import StoreButtons from './StoreButtons'
import Link from 'next/link'
import App from '../../shared-components/event/App'
import dynamic from 'next/dynamic'
import DynamicReduxModal from '../modals/DynamicReduxModal'
import { initDates } from 'actions/pinning'
import {
  closeTicketViewModal,
  openLogOutModal,
  openModal,
  openTicketViewModal,
} from '../../actions/modals'
import PostModal from '../event/posts/PostModal'
import { showConfetti } from 'actions/rsvp'

const RsvpStepper = dynamic(
  () => import('../../../src/components/RsvpWizard/RsvpStepper'),
)
const LogOutDialog = dynamic(() => import('../dialog/LogOutDialog'))
const PinSheet = dynamic(() => import('../../../src/components/event/PinSheet'))
const VerifyEmailDialog = dynamic(
  () => import('../RsvpWizard/VerifyEmailDialogOld'),
)
const AddToCalendarModal = dynamic(
  () => import('../../../src/components/modals/AddToCalendarModal'),
)
const InsightsModal = dynamic(() => import('../modals/Insights/InsightsModal'))

const ConnectedApp = ({
  inDrawer = false,
  inWidget = false,
  isMobileOrTablet = false,
}) => {
  const [isPreview, setIsPreview] = useState(false)
  const router = useRouter()
  const { t } = useTranslation('common')

  const dispatch = useDispatch()
  const isRejected = useSelector((state) => state.event.isRejected)
  const event = useSelector(getCurrentEvent)
  const activeGuest = useSelector(getActiveGuestWithoutNew)
  const error = useSelector((state) => state.event.error)
  const [redirecting, setRedirecting] = useState(false)
  const friendsFulfilled = useSelector(
    (state) => state.user.friends.isFulfilled,
  )
  const currentEventCode = useSelector((state) => state.event.currentEventCode)
  const [hasSentEvent, setHasSentEvent] = useState(false)
  const [commentModalOpen, setCommentModalOpen] = useState(false)
  const { user, ready, isLoggedIn } = useUser()
  const [post, setPost] = useState(null)
  const [posts, setPosts] = useState([])

  let eventCodeFromQuery = null
  let eventIdFromQuery = null
  let fromUserCode = null
  if (router.query) {
    if (router.query.eventCode) {
      eventCodeFromQuery = router.query.eventCode
    }
    if (router.query.eventId) {
      eventIdFromQuery = router.query.eventId
    }
    if (router.query.fc) {
      fromUserCode = router.query.fc
    }
  }
  const guestCodeFromQuery = router.query.gc
  const ticketCodeFromQuery = router.query.tc
  const postIdFromQuery = router.query.postId
  const showDone = router.query.success
  const forceShowRsvp = router.query.forceShowRsvp

  const { ticketsModal } = router.query

  useEffect(() => {
    if (ticketsModal === 'true') {
      dispatch(openTicketViewModal())
    }
  }, [ticketsModal])

  const handleCloseModal = () => {
    dispatch(closeTicketViewModal())
    ensureUrlParameters()
  }

  useEffect(() => {
    if (eventCodeFromQuery && eventCodeFromQuery !== currentEventCode) {
      dispatch(setCurrentEvent(eventCodeFromQuery))
    }
  }, [eventCodeFromQuery, dispatch, currentEventCode])

  useEffect(() => {
    if (showDone && event.privacyType === 'PUBLIC') {
      dispatch(openModal('postRsvp'))
      dispatch(showConfetti())
    } else if (showDone) {
      dispatch(showConfetti())
    }
  }, [showDone, event.privacyType])

  useEffect(() => {
    if (
      event.errors != null &&
      event.errors.find((e) => e.type === 'NOT_YOUR_GUEST_CODE') != null
    ) {
      dispatch(openLogOutModal())
    }
  }, [event.errors])

  useEffect(() => {
    if (inDrawer || inWidget) return
    if (fromUserCode) {
      dispatch(fetchUserByCode(fromUserCode))
      dispatch(setFromUserCode(fromUserCode))

      if (!hasSentEvent) {
        const loadUser = async () => {
          const result = await apiClient.user.getUserByCode(fromUserCode)
          apiClient.general.analyticsEventEvent(
            'open-user-generated-link-event',
            {
              eventId: event.id,
              ofUserId: result.id,
              customProperties: {
                'Link type': 'event',
                'Share type': 'share',
                'Of User ID': result.id,
              },
            },
          )
          setHasSentEvent(true)
        }
        loadUser()
      }
    }
  }, [fromUserCode, dispatch, hasSentEvent])

  useEffect(() => {
    if (inDrawer || inWidget) return
    let inviterId = null
    if (router.query.param?.length > 0) inviterId = router.query.param[0]
    if (inviterId && !hasSentEvent) {
      const loadUser = async () => {
        const result = await apiClient.guest.getByCode(inviterId)
        if (result?.user?.id != null) {
          apiClient.general.analyticsEventEvent(
            'open-user-generated-link-event',
            {
              eventId: event.id,
              ofUserId: result?.user?.id,
              customProperties: {
                'Link type': 'event',
                'Share type': 'invite',
                'Of User ID': result?.user?.id,
              },
            },
          )
          setHasSentEvent(true)
        }
      }
      loadUser()
    }
  }, [router.query, hasSentEvent])

  useEffect(() => {
    if (inDrawer || inWidget) return
    const loadEvent = async () => {
      let localGuestCode = null
      let localTicketCode = null
      if (typeof window !== 'undefined') {
        localTicketCode =
          window.localStorage.getItem(`tc_${eventCodeFromQuery}`) ??
          window.localStorage.getItem(`tc_${eventIdFromQuery}`)
        if (!guestCodeFromQuery && !event.myGuest) {
          localGuestCode =
            window.localStorage.getItem(`gc_${eventCodeFromQuery}`) ??
            window.localStorage.getItem(`gc_${eventIdFromQuery}`)
        } else if (event.myGuest != null && !isLoggedIn) {
          if (!window.sessionStorage.getItem(`welcomed_${event.id}`)) {
            dispatch(
              setSnackbar(
                'notified',
                t('welcomeBackName', {
                  name: getGuestFirstName(event.myGuest),
                }),
              ),
            )
            window.sessionStorage.setItem(`welcomed_${event.id}`, 'true')
          }
        }
      }

      if (
        (eventCodeFromQuery || eventIdFromQuery) &&
        (router.query.s || router.query.guestId || localGuestCode)
      ) {
        let inviterId = null
        if (router.query.param?.length > 0) inviterId = router.query.param[0]
        const fetchedEvent = eventCodeFromQuery
          ? await apiClient.event.byCode(
              eventCodeFromQuery,
              inviterId,
              null,
              localGuestCode,
              localTicketCode,
            )
          : await apiClient.event.get(
              eventIdFromQuery,
              localGuestCode,
              localTicketCode,
            )
        dispatch({
          type: 'FETCH_EVENT_FULFILLED',
          payload: fetchedEvent,
        })

        // Check for external events and redirect if needed
        if (
          fetchedEvent.creationType === 'EXTERNAL' &&
          typeof window !== 'undefined'
        ) {
          const firstPageThatIsOrganisation = fetchedEvent.hosts?.find(
            (h) => h.type === 'ORGANISATION',
          )
          if (firstPageThatIsOrganisation?.model?.username) {
            router.push(
              `/${firstPageThatIsOrganisation.model.username}?eventId=${fetchedEvent.id}`,
            )
            return
          } else if (firstPageThatIsOrganisation?.model?.id) {
            router.push(
              `/pages/${firstPageThatIsOrganisation.model.id}?eventId=${fetchedEvent.id}`,
            )
            return
          }
        }

        dispatch(initDates(fetchedEvent.dateOptions, fetchedEvent.myGuest?.id))

        if (typeof window !== 'undefined') {
          ensureUrlParameters()
        }

        dispatch(loadGuestLocal())
      } else {
        dispatch(loadGuestLocal())
      }
    }
    loadEvent()
  }, [
    eventCodeFromQuery,
    dispatch,
    router.query.eventCode,
    router.query.shallow,
    guestCodeFromQuery,
    event.myGuest,
    user?.id,
  ])

  useEffect(() => {
    if (postIdFromQuery) {
      apiClient.event
        .getPost(postIdFromQuery, guestCodeFromQuery)
        .then((post) => {
          setPost(post)
          setCommentModalOpen(true)
        })
    }
  }, [postIdFromQuery, guestCodeFromQuery])

  useEffect(() => {
    if (event?.myGuest) {
      if (eventCodeFromQuery) {
        window.localStorage.setItem(
          `gc_${eventCodeFromQuery}`,
          event.myGuest.code,
        )
      }
      if (eventIdFromQuery) {
        window.localStorage.setItem(
          `gc_${eventIdFromQuery}`,
          event.myGuest.code,
        )
      }
    }
    if (ticketCodeFromQuery) {
      if (eventCodeFromQuery) {
        window.localStorage.setItem(
          `tc_${eventCodeFromQuery}`,
          ticketCodeFromQuery,
        )
      }
      if (eventIdFromQuery) {
        window.localStorage.setItem(
          `tc_${eventIdFromQuery}`,
          ticketCodeFromQuery,
        )
      }
    }
  }, [event?.myGuest, eventIdFromQuery, eventCodeFromQuery])

  useEffect(() => {
    if (router.query) {
      const userId = router.query.userId
      if (userId) {
        setIsPreview(true)
        mixpanel.identifyUserId(userId)
        mixpanel.register({ 'Is Preview': true })
      }
    }
  }, [router.query, dispatch])

  useEffect(() => {
    if (user.id && !friendsFulfilled) {
      dispatch(fetchFriends())
    }
  }, [user, friendsFulfilled, dispatch])

  useEffect(() => {
    if (router.query) {
      const direct = router.query.d
      if (direct) {
        const eventCode = router.query.eventCode
        window.localStorage &&
          window.localStorage.setItem(`direct_${eventCode}`, 'true')
      }
    }
  }, [router])

  useEffect(() => {
    if (inDrawer || inWidget) return
    if (event && event.name) document.title = event.name
  }, [event, inDrawer, inWidget])

  useEffect(() => {
    if (activeGuest?.pendingTicketOrder) {
      const expiresAt = dayjs(activeGuest.pendingTicketOrder.expiresAt).utc()
      if (expiresAt > dayjs().utc()) {
        setRedirecting(true)
        router.push(
          `/events/${event.id}/checkout?gc=${activeGuest.code}&orderId=${activeGuest.pendingTicketOrder.id}`,
        )
      }
    }
  }, [activeGuest])

  useEffect(() => {
    if (inDrawer || inWidget) return
    const es = new EventSource(
      `${process.env.NEXT_PUBLIC_API_HOST}/events/${event.id}/updates/sse?eventCode=${event.code}`,
    )

    es.onopen = () => console.log('>>> Connection opened!')
    es.onerror = (e) => console.log('SSE ERROR!', e)

    es.onmessage = async (e) => {
      console.log('>>>', e.data)
      if (e.data === 'connected' || e.data === 'stay_alive') {
        console.log('connected with event updates')
      } else {
        try {
          const update = JSON.parse(e.data)
          switch (update.type) {
            case 'HYPE_SCORE':
              console.log('HYPE_SCORE', update.data)
              dispatch(updateHypeScore(update.eventId, update.data))
              break
            case 'NEW_POST':
              const postId = update.data
              const post = await apiClient.post.get(postId, event.myGuest?.code)
              setPosts((prev) => [post, ...prev])
              break
            case 'NEW_POST_COMMENT':
              const commentId = update.data
              const comment = await apiClient.event.getComment(
                commentId,
                event.myGuest?.code,
              )
              if (comment) {
                setPosts((prev) => {
                  const newPosts = [...prev]
                  const postIndex = newPosts.findIndex(
                    (p) => p.id === comment.postId,
                  )
                  if (postIndex !== -1) {
                    if (!newPosts[postIndex].comments) {
                      newPosts[postIndex].comments = []
                    }
                    const commentExists = newPosts[postIndex].comments?.some(
                      (c) => c.id === comment.id,
                    )

                    if (!commentExists) {
                      if (comment.parentCommentId == null) {
                        newPosts[postIndex].comments = [
                          ...newPosts[postIndex].comments,
                          comment,
                        ].sort(
                          (a, b) =>
                            new Date(a.createdAt).getTime() -
                            new Date(b.createdAt).getTime(),
                        )
                      } else {
                        newPosts[postIndex].threadCount += 1
                      }
                      newPosts[postIndex].commentCount += 1
                    }
                  }
                  return newPosts
                })
              }
              break
            case 'NEW_POST_EMOJI':
              const postLike = update.data
              if (postLike.userId === user.id) break
              setPosts((prev) => {
                const newPosts = [...prev]
                const postIndex = newPosts.findIndex(
                  (p) => p.id === postLike.entityId,
                )
                if (postIndex !== -1) {
                  const emojiIndex = newPosts[
                    postIndex
                  ].emojiResponses.findIndex((e) => e.emoji === postLike.emoji)
                  if (emojiIndex !== -1) {
                    newPosts[postIndex].emojiResponses[emojiIndex].count += 1
                  } else {
                    newPosts[postIndex].emojiResponses.push({
                      emoji: postLike.emoji,
                      count: 1,
                      reactedByYou: false,
                      users: [],
                    })
                  }
                }
                return newPosts
              })
              break
            case 'NEW_POST_COMMENT_EMOJI':
              const commentLike = update.data
              if (commentLike.userId === user.id) break
              setPosts((prev) => {
                const newPosts = [...prev]
                const comment = newPosts
                  .flatMap((p) => p.comments)
                  .find((c) => c.id === commentLike.entityId)
                if (comment != null) {
                  const emojiIndex = comment.emojiResponses.findIndex(
                    (e) => e.emoji === commentLike.emoji,
                  )
                  if (emojiIndex !== -1) {
                    comment.emojiResponses[emojiIndex].count += 1
                  } else {
                    comment.emojiResponses.push({
                      emoji: commentLike.emoji,
                      count: 1,
                      reactedByYou: false,
                      users: [],
                    })
                  }
                }
                return newPosts
              })
              break
            case 'NEW_RSVP':
              const guestId = update.data.guestId
              const guest = await apiClient.guest.get(
                guestId,
                event.code,
                event.myGuest?.code,
              )
              if (guest) {
                dispatch(addGuestToEvent(update.eventId, guest))
              }
              break
            case 'TICKET_AVAILABILITY_CHANGE':
              // Should update to only show sold out/not sold out
              // dispatch(
              //   updateJoinOptionAvailable(
              //     update.eventId,
              //     update.data.joinOptionId,
              //     update.data.amount,
              //   ),
              // )
              break
            case 'EVENT':
              console.log('EVENT', update.data)
              break
          }
        } catch (e) {
          console.error(e)
        }
      }
    }
    return () => es.close()
  }, [event.id])

  const appProps = {
    event,
    isFulfilled: !!event,
    isRejected,
    error,
    StoreButtons: isPreview ? undefined : StoreButtons,
    LinkComponent: Link,
    activeGuest: activeGuest,
    posts,
    setPosts,
    forceShowRsvp,
  }

  // Add this function outside your useEffect to make it reusable
  const ensureUrlParameters = () => {
    if (typeof window === 'undefined') return

    const currentUrl = window.location.pathname
    const params = new URLSearchParams()

    // If we already have either u or g parameter, update it with the latest value
    // If we don't have either, add the appropriate one
    if (user?.id) {
      params.set('u', user.id)
    } else if (event?.myGuest?.id) {
      params.set('g', event.myGuest.id)
    } else {
      params.set('u', '0')
    }

    // Reconstruct the URL with parameters
    const newUrl = `${currentUrl}?${params.toString()}`

    // Only update if the URL has changed
    if (newUrl !== window.location.pathname + window.location.search) {
      window.history.replaceState(window.history.state, '', newUrl)
    }
  }

  // Add a dedicated useEffect to handle URL parameter persistence
  useEffect(() => {
    if (inDrawer || inWidget) return
    ensureUrlParameters()
  }, [user?.id, event?.myGuest?.id, inDrawer, inWidget])

  // Intercept history changes
  useEffect(() => {
    if (inDrawer || inWidget || typeof window === 'undefined') return

    const handlePopState = () => {
      ensureUrlParameters()
    }

    window.addEventListener('popstate', handlePopState)

    return () => {
      window.removeEventListener('popstate', handlePopState)
    }
  }, [inDrawer, inWidget])

  return (
    <>
      <App
        {...appProps}
        inDrawer={inDrawer}
        inWidget={inWidget}
        isMobileOrTablet={isMobileOrTablet}
        redirecting={redirecting}
      />
      {event.type === 'PINNING' && <PinSheet />}
      <AddToCalendarModal />
      <DynamicReduxModal reduxName='rsvp' DynamicComponent={RsvpStepper} />
      {/*<RsvpStepper />*/}
      <LogOutDialog />
      <VerifyEmailDialog />
      <InsightsModal />
      <PostModal
        open={commentModalOpen}
        onClose={() => setCommentModalOpen(false)}
        post={post}
        setPost={setPost}
        onDelete={() => {
          setCommentModalOpen(false)
          onDelete()
        }}
      />
    </>
  )
}

export default ConnectedApp

import React from 'react'
import { withTranslation } from 'react-i18next'
import { connect, useSelector } from 'react-redux'

import { mdiApple } from '@mdi/js'
import PlayIcon from 'svg/GooglePlay'
import { useDownloadApp } from 'shared-components/utils/downloadApp'

import { Fab, SvgIcon, Typography, useMediaQuery } from '@mui/material'
import { withStyles } from 'tss-react/mui'
import classNames from 'clsx'
import {
  getActiveGuest,
  getCurrentEvent,
  getHasResponded,
} from 'selectors/event'
import { useTheme } from '@mui/material/styles'
import { getIsMobileOrTablet } from '../../utils/isMobileOrTablet'

const styles = (theme) => ({
  buttonContainer: {
    marginTop: theme.spacing(2),
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit,minmax(155px, 0.5fr))',
    gridGap: theme.spacing(1, 1),
  },
  containerTinyScreen: {
    gridTemplateColumns: 'repeat(auto-fit,minmax(75px, 1fr))',
    gridGap: theme.spacing(1, 1),
  },
  centerButtons: {
    justifyContent: 'center',
  },
  fabRoot: {
    boxShadow:
      '0 4px 5px 0 rgba(0, 0, 0, 0.04), 0 1px 10px 0 rgba(0, 0, 0, 0.04), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
    justifyContent: 'left',
    overflow: 'hidden',
    transition: 'transform 200ms, box-shadow 200ms, background 200ms',
    '&:hover': {
      boxShadow:
        '0 10px 8px 1px rgba(0, 0, 0, 0.04), 0 3px 14px 2px rgba(0, 0, 0, 0.04), 0 5px 5px -3px rgba(0, 0, 0, 0.06)',
      transform: 'translateY(-2px)',
      backgroundColor: '#333333 !important',
    },
    minWidth: '157px',
  },
  fabSmall: {
    minWidth: '120px',
    padding: theme.spacing(0, 1, 0, 1.25),
  },
  button: {
    color: 'white',
    float: 'right',
    textTransform: 'none',
    background: 'black',
    '&:hover': {
      backgroundColor: '#333333 !important',
    },
  },
  iconContainer: {
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  labelContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: theme.spacing(2),
  },
  labelContainerSmall: {
    marginLeft: theme.spacing(1),
  },
  labelTop: {
    color: 'white',
    textAlign: 'left',
    fontSize: '0.60rem',
    lineHeight: '1em',
  },
  labelBottom: {
    color: 'white',
    textAlign: 'left',
    lineHeight: '1em',
    fontSize: '.9rem',
  },
  left: {
    justifyContent: 'left',
  },
  darkMode: {
    background: '#333333',
  },
})

const StoreButtons = ({
  classes,
  t,
  callback,
  action,
  className,
  centerButtons = false,
  leftOnMobile,
  small,
  darkMode = false,
}) => {
  const event = useSelector(getCurrentEvent)

  const theme = useTheme()
  const isTinyScreen = useMediaQuery(theme.breakpoints.down(361))
  const { onDownloadClick, QRCodeModal } = useDownloadApp({
    utm_medium: action,
    event,
    usingHref: false,
  })
  // const isTinyScreen = false

  const onGetAndroid = () => {
    if (!getIsMobileOrTablet()) {
      window.open(
        'https://play.google.com/store/apps/details?id=com.vlinderstorm.bash',
      )
    } else {
      if (callback) callback(action, 'Android')
      else {
        onDownloadClick()
      }
    }
  }
  const onGetIos = () => {
    if (!getIsMobileOrTablet()) {
      window.open('https://apps.apple.com/app/bash-sociale-events/id1448137786')
    } else {
      if (callback) callback(action, 'iOS')
      else {
        onDownloadClick()
      }
    }
  }
  return (
    <div
      className={classNames(className, classes.buttonContainer, {
        [classes.containerTinyScreen]: isTinyScreen,
        [classes.centerButtons]: centerButtons,
        [classes.left]: leftOnMobile && getIsMobileOrTablet(),
      })}
    >
      {/* {md?.getOSName() !== 'iOS' && */}
      <QRCodeModal />
      <Fab
        variant='extended'
        size='large'
        onClick={onGetAndroid}
        classes={{
          root: classNames(classes.fabRoot, {
            [classes.fabSmall]: small || isTinyScreen,
            [classes.darkMode]: darkMode,
          }),
        }}
        className={classes.button}
      >
        <div className={classes.iconContainer}>
          <PlayIcon />
        </div>
        <div
          className={classNames(classes.labelContainer, {
            [classes.labelContainerSmall]: small,
          })}
        >
          {!isTinyScreen && (
            <Typography variant='subtitle1' className={classes.labelTop}>
              {t('getItOn')}
            </Typography>
          )}
          <Typography variant='subtitle1' className={classes.labelBottom}>
            {t('googlePlay')}
          </Typography>
        </div>
      </Fab>
      {/* } */}
      {/* {md?.getOSName() !== 'Android' && */}
      <Fab
        variant='extended'
        size='large'
        onClick={onGetIos}
        classes={{
          root: classNames(classes.fabRoot, {
            [classes.fabSmall]: small || isTinyScreen,
            [classes.darkMode]: darkMode,
          }),
        }}
        className={classes.button}
      >
        <div className={classes.iconContainer}>
          <SvgIcon>
            <path d={mdiApple} />
          </SvgIcon>
        </div>
        <div
          className={classNames(classes.labelContainer, {
            [classes.labelContainerSmall]: small,
          })}
        >
          {!isTinyScreen && (
            <Typography variant='subtitle1' className={classes.labelTop}>
              {t('downloadOn')}
            </Typography>
          )}
          <Typography variant='subtitle1' className={classes.labelBottom}>
            {t('appStore')}
          </Typography>
        </div>
      </Fab>
      {/* } */}
    </div>
  )
}

const mapStateToProps = (state) => ({
  event: getCurrentEvent(state),
  currentGuestId: getActiveGuest(state).id,
  hasResponded: getHasResponded(state),
})

export default connect(mapStateToProps)(
  withStyles(withTranslation('common')(StoreButtons), styles),
)
